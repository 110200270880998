import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import { Profile } from "@/services/Profile";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"]
    }
  },
  {
    path: '/book-berth',
    name: 'BookBerth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/BookBerth.vue')
    }
  },
  {
    path: '/manage-port',
    name: 'ManagePort',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ManagePort.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/worker-tasks',
    name: 'WorkerTasks',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/WorkerTasks.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/mailing',
    name: 'Mailing',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Mailing.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/port-timeline',
    name: 'PortTimeline',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/PortTimeline.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/migration',
    name: 'Migration',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Migration.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: true,
    },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Clients.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Settings.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: true,
    },
  },
  {
    path: '/port-map',
    name: 'PortMap',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/PortMap.vue')
    },
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: false,
    },
  },
  {
    path: '/find-port',
    name: 'FindPort',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/FindPort.vue')
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
    meta: {
      requiresAuth: true,
      //profiles: ["user", "worker", "distributor", "super-admin"],
    },
  },
  {
    path: "/workers",
    name: "Workers",
    component: () =>
      import("../components/index/Workers.vue"),
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
      only_admin: true,
    },
  },
  {
    path: "/incidences",
    name: "Incidences",
    component: () => import(/* webpackChunkName: "home" */ "../views/IncidenceIndex.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoice-settings",
    name: "InvoiceSettings",
    component: () =>
      import("../views/InvoiceSettings.vue"),
    meta: {
      requiresAuth: true,
      profiles: ["worker"],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      profiles: ["user", "worker", "distributor", "super-admin"],
    },
  },
  {
    path: "/restore-password",
    name: "RestorePassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RestorePassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/email-confirmation",
    name: "EmailConfirmation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmailConfirmation.vue"),
  },
  {
    path: "/email-confirmed",
    name: "EmailConfirmed",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmailConfirmed.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forbidden.vue"),
  }, {
    path: '/payments',
    name: 'PortPayments',
    component: () => import("../views/PortPayments.vue")
  },
  {
    path: "/payment-status",
    name: "PaymentStatus",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment/Status.vue"),
  },
  /*{
    path: "/test",
    name: "test",
    component: () =>
      import("../views/testComponents/TestComponent.vue"),
  },*/
  {
    path: "/works",
    name: "works",
    component: () =>
      import("../views/dryDock/Works.vue"),
  },
  {
    path: '/work/:id', // Define la ruta con el parámetro :id
    name: 'work', // Nombre opcional para la ruta
    component: () =>
      import("../views/dryDock/Work.vue"),

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  var profile = new Profile();
  // Determine si la ruta a seguir requiere Auth
  // to.matched.some(r => r.meta.requireAuth) or to.meta.requiresAuth
  if (to.matched.some((r) => r.meta.requiresAuth)) {
    if (profile.isLogged()) {
      if (to.meta.only_admin && !profile.isAdmin()) {
        next({
          name: "Forbidden",
        });
      }
      if (
        !to.meta.profiles ||
        (to.meta.profiles &&
          to.meta.profiles.indexOf(profile.profile_type) > -1)
      ) {
        next(); // Si hay un token, haga una solicitud y el fondo verificará el token
      } else {
        next({
          name: "Forbidden",
        });
      }
    } else {
      next({
        name: "Login",
        // Utilice la ruta de la ruta (pero sin permiso) que solo desea usar como parámetro,
        // de modo que pueda saltar a la ruta directamente después de un inicio de sesión exitoso.
        // Esto debe evaluarse más a fondo en la página de inicio de sesión
        query: { redirect: to.fullPath },
      });
    }
  } else if (to.matched.some((r) => r.meta.requiresAuth === false)) {
    if (profile.isLogged()) {
      next({
        name: "Home",
        //query: { redirect: to.path },
      });
    } else {
      next();
    }
  } else {
    next(); // Si no se necesita ningún token, déjelo ir
  }
});

export default router
